import React from "react";
import { Link } from "react-router-dom";

import StrategyImage from "../assets/images/svg/design-thinking.svg";
import departmentImage from "../assets/images/svg/coding.svg";
import launchImage from "../assets/images/svg/office-desk.svg";
import inspection from "../assets/images/svg/Inspection.svg";
import packing from "../assets/images/svg/Packing.svg";
import Cooling from "../assets/images/svg/Cooling.svg";
import process from "../assets/images/svg/Process.svg";
import wood from "../assets/images/svg/Wood.svg";

export default function Services() {
  const team = [
    {
      title: "Vision",
      icon: "eye",
      subtext: "To  be the world’s leading wood charcoal supplier",
    },
    {
      title: "Mision",
      icon: "focus-target",
      subtext: "To  be the world’s leading wood charcoal supplier",
    },
    {
      title: "Goals",
      icon: "globe",
      subtext: "To become leading exportes of wood charcoal",
    },
    {
      title: "Price",
      icon: "usd-circle",
      subtext: "Reasonable and Competitive",
    },
    {
      title: "Quality",
      icon: "check-circle",
      subtext: "Stable and meets buyer’s expecations",
    },
    {
      title: "Customer",
      icon: "head-side",
      subtext: "All Accross the world shipment!",
    },
  ];

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-orange-600 text-base font-medium uppercase mb-2">
                What We Do ?
              </h6>
              <h3 className="mb-4 md:text-2xl text-xl font-semibold dark:text-white md:mb-0">
                Perfect Solution For Your <br /> Business: Ignite Success with
                Our Charcoal Products
              </h3>
            </div>

            <div>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl">
                Unveiling the Artistry of Nature: Elevating Everyday Moments
                with Our Charcoal Products - Ignite, Enhance, Transform!.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
            {team.map((item, key) => (
              <div
                key={key}
                className={`features p-6 ${
                  key % 2 === 0
                    ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800"
                    : "shadow-xl shadow-slate-100 dark:shadow-slate-800"
                } transition duration-500 rounded-3xl mt-8`}
              >
                <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`uil uil-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link
                    to="#"
                    className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium"
                  >
                    {item.title}
                  </Link>
                  <p className="text-slate-400 mt-3">{item.subtext}</p>

                  {/* <div className="mt-5">
                    <Link
                      to="#"
                      className="btn btn-link hover:text-orange-600 dark:hover:text-orange-600 after:bg-orange-600 dark:text-white transition duration-500"
                    >
                      Read More <i className="uil uil-arrow-right"></i>
                    </Link>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">
              Work Process
            </h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
              Charcoal Making Process
            </h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
              Embrace the Ember: Curate Culinary Excellence with Our Artisanal
              Charcoal Elixirs - Where Every Sizzle Sparks Unmatched Flavor!
            </p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={wood} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">
                        1. Incoming Material
                      </h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                        Main raw Material are hardwood such as Guava,Mango,
                        Halaban and rambung klin. Supervisor ensure that the raw
                        amterials received are acceptable
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">
                        2. Production
                      </h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                        Hardwood are then carbonized in the klin for
                        approximately 10-20 days. The process is performed by
                        flowing hot smoke from burning wood into the klin. The
                        carbonization process is completed when the smoke output
                        fromthe top of the klin is clear.
                      </p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={process} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={Cooling} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">
                        3. Cooling and Weathering
                      </h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                        After carbonization process is complete, cooling and
                        Weathering is carried out for a minimum of 14 days
                        before final packing
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="md:order-1 order-2">
                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">
                        4. Final Inspection and Testing
                      </h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                        Before final packing, we conduct temperature and
                        moisture checks in the goods warehouse. when the result
                        of the results are acceptable, it is then packed into
                        the inner box.
                      </p>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                        Quality checks consists of the following parameters:
                        <ol>
                          <li>
                            Sieving the different sizes of the final goods and
                            filtering dost or any contamination
                          </li>
                          <li>
                            Visual Check of hardwood charcoal and checking the
                            weight of the product
                          </li>
                          <li>
                            The temperature of the product before final packing
                            is not more than 5 C above the ambient temperature.
                          </li>
                        </ol>
                      </p>
                    </div>
                  </div>
                  <div className="md:order-2 order-1">
                    <div className="duration duration-right md:ms-7 relative">
                      <img src={inspection} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="timeline-item mt-5 pt-4">
                <div className="grid sm:grid-cols-2">
                  <div className="mt-4 mt-sm-0">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={packing} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 mt-sm-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">
                        5. Packing
                      </h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">
                        Finished goods are packed according to customer
                        requirements. There are 3 kg, 10 kg, 18 kg, 20 kg
                        packaging boxes and 18 kg, 20 kg, 25 kg, 30 kg PP woven
                        bags. All inner box packaging is eqquiped with inner
                        plastic and is vacuumed to keep the humidity of the
                        Finished product.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
