import React from "react";

import Image1 from "../assets/images/blog/1.jpg";
import Image2 from "../assets/images/blog/2.jpg";
import Image3 from "../assets/images/blog/3.jpg";

import { Link as Link2 } from "react-router-dom";

export default function Blog() {
  const blog = [
    {
      id: "1",
      title: "Worldwide Distribution",
      description:
        "From the Grilling Capitals of Korea, Japan, and China to the Sizzling Scenes in Europe, Australia, and Beyond—Our Premium Charcoal Products Reach Every Corner of the Globe.",
      image: Image1,
    },
    {
      id: "2",
      title: "Quality of The product",
      description:
        "Elevating Excellence: Our Hardwood Charcoal Products Define Unrivaled Quality, Transforming Every Grilling Experience Into a Masterpiece.",
      image: Image2,
    },
    // {
    //   id: "3",
    //   title: "Best of the best Services",
    //   description:
    //     "It has survived not only five centuries but leap in typesetting.",
    //   image: Image3,
    // },
  ];

  return (
    <>
      <section className="relative md:py-24 py-16" id="blog">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">
              Our Customer
            </h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
              All Accross The World
            </h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
              Elevate Your Grilling Symphony: Unleashing the Culinary Alchemy of
              Our Exquisite Hardwood Charcoal, Where Each Ember Ignites a
              Symphony of Unparalleled Flavor Complexity.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-8 mx-auto">
            {blog.map((item, key) => (
              <div
                key={key}
                className="blog relative rounded-md shadow shadow-slate-200 dark:shadow-slate-800 overflow-hidden"
              >
                <img src={item.image} alt="" />
                <div className="content p-6">
                  {/* <Link2
                    to="#"
                    className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium"
                  > *
                 
                  {/* </Link2> */}

                  <p className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">
                    {item.title}
                  </p>
                  <p className="text-slate-400 mt-3">{item.description}</p>
                  <div className="mt-5">
                    <Link2
                      to="#"
                      className="btn btn-link hover:text-orange-600 dark:hover:text-orange-600 after:bg-orange-600 dark:text-white transition duration-500"
                    >
                      {/* Read More <i className="uil uil-arrow-right"></i> */}
                    </Link2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
